import React from "react";

const Unpermitted = () => {
  return (
    <div>
      <p>Unauthorized Access</p>
    </div>
  );
};

export default Unpermitted;
