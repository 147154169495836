import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { FormWrapper } from "../../../hoc/layout/elements";
import Heading from "../../../components/UI/Heading/Heading";
import Button from "../../../components/UI/Forms/Button/Button";
import Message from "../../../components/UI/Forms/Message/Message";

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const VerifyEmail = ({ loading, error, sendVerification, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <FormWrapper>
      <Heading color="black" size="h1">
        Verify your email
      </Heading>
      <Heading color="black" bold size="h4">
        Please go to your inbox and verify your email before proceeding
      </Heading>
      <Button
        onClick={() => sendVerification()}
        disabled={loading}
        loading={loading ? "Sending email..." : null}
      >
        Resend the verification email
      </Button>
      <MessageWrapper>
        <Message error show={error}>
          {error}
        </Message>
      </MessageWrapper>
      <MessageWrapper>
        <Message success show={error === false}>
          Message sent successfully
        </Message>
      </MessageWrapper>
    </FormWrapper>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.verifyEmail.loading,
  error: auth.verifyEmail.error
});

const mapDispatchToProps = {
  sendVerification: actions.verifyEmail,
  cleanUp: actions.clean
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
