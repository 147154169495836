import React from "react";
import styled from "styled-components";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
  Container,
  FormWrapper,
  StyledForm,
} from "../../../hoc/layout/elements";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Heading from "../../../components/UI/Heading/Heading";
import Button from "../../../components/UI/Forms/Button/Button";

import CloseIcon from "@material-ui/icons/Close";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 30rem;
  padding: 3rem;
  border-left: 1px solid #616161;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  max-height: 30rem;
  overflow-y: scroll;
  border: 1px solid black;
  padding: 1rem;
`;

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  list-style-type: none;
`;

const Shared = styled.p`
  font-size: 1.2rem;
`;

const ActionIcon = styled(CloseIcon)`
  margin: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

const SharingSidebar = ({ roomId, sharedWith, addShare, revokeShare }) => {
  return (
    <Wrapper>
      <Formik
        initialValues={{
          shareEmail: "",
        }}
        // validationSchema={}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await addShare({
            roomId: roomId,
            user: values.shareEmail,
          });
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <StyledForm>
            <Heading noMargin size="h2">
              Share
            </Heading>

            <Label>Email Address:</Label>
            <Field type="email" name="shareEmail" component={Input} />

            <Button type="submit" disabled={!isValid || isSubmitting}>
              +
            </Button>
          </StyledForm>
        )}
      </Formik>

      <div>
        <Heading size="h3">Shared with:</Heading>
        <Ul>
          {sharedWith &&
            sharedWith.map((share) => (
              <Li>
                <Shared>{share}</Shared>
                <div>
                  <ActionIcon
                    onClick={async () => {
                      await revokeShare({ user: share, roomId: roomId });
                    }}
                  />
                </div>
              </Li>
            ))}
        </Ul>
      </div>
    </Wrapper>
  );
};

export default SharingSidebar;
