import React from "react";
import styled from "styled-components";
import File from "./File";

const StyledFiles = styled.ul`
  list-style-type: none;
  width: 100%;
`;

const Files = ({ filesList, fullPath, role, setFileModalOpened }) => {
  console.log(filesList);
  return (
    <div>
      <StyledFiles>
        {filesList &&
          filesList.map((file) => {
            if (file === "temp.txt") return;
            return (
              <File
                key={file.md5Hash}
                fileName={file}
                path={fullPath}
                role={role}
                setFileModalOpened={setFileModalOpened}
              />
            );
          })}
      </StyledFiles>
    </div>
  );
};

export default Files;
