import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading/Heading";
import Message from "../../../components/UI/Forms/Message/Message";

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("Your first name is required"),
  lastName: Yup.string().required("Your first name is required"),
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string()
    .required("The password is required.")
    .min(8, "Password must contain at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], `Password doesn't match`)
    .required("You must confirm your password")
});

const SignUp = ({ signUp, loading, error, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
      }}
      validationSchema={SignUpSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await signUp(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <StyledForm>
            <Heading noMargin size="h1">
              Sign Up!
            </Heading>
            <Heading size="h4">
              Fill in the details to register with an account
            </Heading>

            <Label>First Name</Label>
            <Field type="text" name="firstName" component={Input} />

            <Label>Last Name</Label>
            <Field type="text" name="lastName" component={Input} />

            <Label>Email</Label>
            <Field type="email" name="email" component={Input} />

            <Label>Password</Label>
            <Field type="password" name="password" component={Input} />

            <Label>Confirm Password</Label>
            <Field type="password" name="confirmPassword" component={Input} />

            <Button
              disabled={!isValid || isSubmitting}
              loading={loading ? "Signing Up..." : null}
              type="submit"
            >
              Submit
            </Button>
            <MessageWrapper>
              <Message error show={error}>
                {error}
              </Message>
            </MessageWrapper>
          </StyledForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
});

const mapDispatchToProps = {
  signUp: actions.signUp,
  cleanUp: actions.clean
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
