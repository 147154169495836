import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => (props.color ? props.color : "#616161")};
  font-weight: 700;
  padding: ${props => (props.noPadding ? "0" : "1rem 2rem")};
  font-size: 1.3rem;
  margin-bottom: ${props => (props.noMargin ? "0rem" : "2rem")};
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    transform: ${props => (props.hover ? "translateY(-3px)" : "none")};
  }

  &:active {
    transform: translateY(2px);
  }
`;

const CustomLink = ({ children, link, color, noMargin, noPadding, hover }) => {
  return (
    <StyledLink
      to={link}
      color={color}
      noMargin={noMargin}
      noPadding={noPadding}
      hover={hover}
    >
      {children}
    </StyledLink>
  );
};

export default CustomLink;
