import * as actions from "../actions/actionTypes";
import { Roles } from "../../utils/enums";

const fetchWithUserToken = async (user, endpoint, method, payload = null) => {
  const firebaseToken = await user.getIdToken();
  console.log(firebaseToken);
  const requestInit = {
    method: method,
  };
  const reqHeaders = new Headers();
  reqHeaders.append("Authorization", `Bearer ${firebaseToken}`);
  if ((method === "POST" || method === "PUT") && payload !== null) {
    reqHeaders.append("content-type", "application/json");
    requestInit.body = JSON.stringify(payload);
  }
  requestInit.headers = reqHeaders;

  const apiBase = "https://us-central1-data-room-167af.cloudfunctions.net/api";
  const request = new Request(`${apiBase}${endpoint}`, requestInit);

  return fetch(request);
};

export const setRoomPath = (data) => async (dispatch) => {
  dispatch({ type: actions.SET_PATH, payload: data.path });
};

// upload file
export const uploadFile = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.UPLOAD_FILE_START });
  try {
    let user = firebase.auth().currentUser;
    const firebaseToken = await user.getIdToken();
    const formData = new FormData();
    formData.append("file", data.file);
    const reqHeaders = new Headers();
    reqHeaders.append("Authorization", `Bearer ${firebaseToken}`);
    const requestInit = {
      method: "POST",
      headers: reqHeaders,
      body: formData,
    };

    const apiBase =
      "https://us-central1-data-room-167af.cloudfunctions.net/api";
    const request = new Request(`${apiBase}/room/${data.path}`, requestInit);

    const response = await fetch(request);
    const json = await response.json();

    dispatch({ type: actions.UPLOAD_FILE_SUCCESS, payload: json });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.UPLOAD_FILE_FAIL, payload: err.message });
  }
};

// delete file
export const deleteFile = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.DELETE_FILE_START });
  try {
    let user = firebase.auth().currentUser;

    console.log(`/room/${data.path}${data.fileName}`);
    await fetchWithUserToken(
      user,
      `/room/${data.path}${data.fileName}`,
      "DELETE"
    );

    dispatch({ type: actions.DELETE_FILE_SUCCESS, payload: data.fileName });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.DELETE_FILE_FAIL, payload: err.message });
  }
};

// download file
export const downloadFile = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const fileRef = firebase
    .storage()
    .ref()
    .child(data.path)
    .child(data.fileName);
  dispatch({ type: actions.DOWNLOAD_FILE_START });
  try {
    const downloadURL = await fileRef.getDownloadURL();
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      let blob = xhr.response;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    };
    xhr.open("GET", downloadURL);
    xhr.send();
    dispatch({ type: actions.DOWNLOAD_FILE_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.DOWNLOAD_FILE_FAIL, payload: err.message });
  }
};

// preview file
export const previewFile = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch({ type: actions.FILE_PREVIEW_START });
  const firebase = getFirebase();
  try {
    const prefix = `${data.path}${data.fileName}`;
    const fileType = data.fileName.split(".").pop();
    let user = firebase.auth().currentUser;
    const response = await fetchWithUserToken(
      user,
      `/file?prefix=${prefix}`,
      "GET"
    );
    if (response.status === 403) {
      dispatch({
        type: actions.SET_ROOM,
        payload: { role: Roles.UNPERMITTED },
      });
    }
    const json = await response.json();
    const url = json[0];

    console.log(url);

    dispatch({
      type: actions.FILE_PREVIEW_SUCCESS,
      payload: { url: url, fileType: fileType },
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.FILE_PREVIEW_FAIL, payload: err.message });
  }
};

// get user room
export const getRoom = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const roomPath = data.path;
  try {
    let user = firebase.auth().currentUser;
    const response = await fetchWithUserToken(user, `/room/${roomPath}`, "GET");
    if (response.status === 403) {
      dispatch({
        type: actions.SET_ROOM,
        payload: { role: Roles.UNPERMITTED },
      });
    }
    const json = await response.json();
    console.log(json);

    dispatch({ type: actions.SET_ROOM, payload: json });
  } catch (err) {
    console.log(err);
  }
};

// get user rooms
export const getRooms = () => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  try {
    let user = firebase.auth().currentUser;
    const response = await fetchWithUserToken(user, "/rooms", "GET");
    const json = await response.json();

    dispatch({
      type: actions.SET_ROOMS,
      payload: { owned: json.owned, shared: json.sharedWith },
    });
  } catch (err) {
    console.log(err);
  }
};

// create new room
export const createRoom = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.CREATE_ROOM_START });
  try {
    let user = firebase.auth().currentUser;
    const response = await fetchWithUserToken(user, "/rooms", "POST", {
      roomName: data.roomName,
    });
    const room = await response.json();

    dispatch({ type: actions.CREATE_ROOM_SUCCESS, payload: room });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.CREATE_ROOM_FAIL, payload: err.message });
  }
};

// create new room
export const createFolder = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.CREATE_FOLDER_START });
  try {
    let user = firebase.auth().currentUser;
    await fetchWithUserToken(user, `/room/${data.path}${data.folder}/`, "POST");
    dispatch({ type: actions.CREATE_FOLDER_SUCCESS, payload: data.folder });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.CREATE_FOLDER_FAIL, payload: err.message });
  }
};

// delete folder
export const deleteFolder = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.DELETE_FOLDER_START });
  try {
    let user = firebase.auth().currentUser;
    await fetchWithUserToken(
      user,
      `/room/${data.path}${data.folder}/`,
      "DELETE"
    );
    dispatch({ type: actions.DELETE_FOLDER_SUCCESS, payload: data.folder });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.DELETE_FOLDER_FAIL, payload: err.message });
  }
};

// delete room
export const deleteRoom = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.DELETE_ROOM_START });
  try {
    let user = firebase.auth().currentUser;
    await fetchWithUserToken(user, `/rooms/${data.roomId}`, "DELETE");
    dispatch({ type: actions.DELETE_ROOM_SUCCESS, payload: data.roomId });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.DELETE_ROOM_FAIL, payload: err.message });
  }
};

export const revokeShare = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  console.log(data);
  try {
    // Obtain current user from firestore
    const doc = await firestore.collection("rooms").doc(data.roomId).get();

    // Update firestore rooms collection
    let sharedWith = doc.data().shared_with || [];
    sharedWith = sharedWith.filter((user) => user !== data.user);
    await firestore.collection("rooms").doc(data.roomId).update({
      shared_with: sharedWith,
    });

    dispatch(getRoom({ path: `${data.roomId}/` }));
  } catch (err) {
    console.log(err);
  }
};

export const addShare = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    // Obtain current user from firestore
    const doc = await firestore.collection("rooms").doc(data.roomId).get();

    // Update firestore rooms collection
    let sharedWith = doc.data().shared_with || [];
    sharedWith.push(data.user.toLowerCase());
    sharedWith = [...new Set(sharedWith)];
    await firestore.collection("rooms").doc(data.roomId).update({
      shared_with: sharedWith,
    });

    dispatch(getRoom({ path: `${data.roomId}/` }));
  } catch (err) {}
};

// analytics event for accessing a room
export const accessRoom = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const analytics = firebase.analytics();

  analytics.logEvent("room_accessed", { email: data.email });
};

// clean up room
export const cleanRoom = () => ({
  type: actions.CLEAN_ROOM,
});
