import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 4rem;
  }
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;

  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${props => (props.show ? "visible" : "hidden")};
  opacity: ${props => (props.show ? "1" : "0")};
  transition: all 0.2s;
  position: absolute;
  transform: ${props => (props.show ? "translateY(20px)" : "translateY(5px)")};
  bottom: 0;
  left: 0;
  font-weight: 500;
  font-size: 1.2rem;
`;

const Input = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <InputWrapper>
      <StyledInput {...field} {...props} />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </InputWrapper>
  );
};

export default Input;
