import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading/Heading";
import Message from "../../../components/UI/Forms/Message/Message";
import Modal from "../../../components/UI/Modal/Modal";

import * as actions from "../../../store/actions";

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Your first name is required"),
  lastName: Yup.string().required("Your first name is required"),
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string().min(8, "Password must contain at least 8 characters"),
  confirmPassword: Yup.string().when("password", {
    is: password => password && password.length > 0,
    then: Yup.string()
      .required("You must confirm your password")
      .oneOf([Yup.ref("password"), null], `Password doesn't match`)
  })
});

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
`;

const DeleteWrapper = styled.div`
  cursor: pointer;
  color: var(--color-errorRed);
  font-size: 1.3rem;
  transition: all 0.2s;
  font-weight: 700;
  margin-top: 1rem;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
`;

const DeleteButton = styled(Button)`
  font-weight: 700;
  color: var(--color-white);
  background-color: var(--color-errorRed);
  border: 0;
`;

const Profile = ({
  firebase,
  loading,
  error,
  editProfile,
  cleanUp,
  loadingDelete,
  errorDelete,
  deleteUser
}) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  const [modalOpened, setModalOpened] = useState(false);

  if (!firebase.profile.isLoaded) return null;
  return (
    <>
      <Formik
        initialValues={{
          firstName: firebase.profile.firstName,
          lastName: firebase.profile.lastName,
          email: firebase.auth.email,
          password: "",
          confirmPassword: ""
        }}
        validationSchema={ProfileSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // edit profile here
          await editProfile(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <FormWrapper>
            <StyledForm>
              <Heading noMargin size="h1">
                Edit your profile
              </Heading>
              <Heading size="h4">Make edits to your profile</Heading>

              <Label>First Name</Label>
              <Field type="text" name="firstName" component={Input} />

              <Label>Last Name</Label>
              <Field type="text" name="lastName" component={Input} />

              <Label>Email</Label>
              <Field type="email" name="email" component={Input} />

              <Label>Password</Label>
              <Field type="password" name="password" component={Input} />

              <Label>Confirm Password</Label>
              <Field type="password" name="confirmPassword" component={Input} />

              <Button
                disabled={!isValid || isSubmitting}
                loading={loading ? "Signing Up..." : null}
                type="submit"
              >
                Submit
              </Button>
              <MessageWrapper>
                <Message error show={error}>
                  {error}
                </Message>
              </MessageWrapper>
              <MessageWrapper>
                <Message success show={error === false}>
                  Profile edited successfully
                </Message>
              </MessageWrapper>
              <DeleteWrapper onClick={() => setModalOpened(true)}>
                Delete my account
              </DeleteWrapper>
            </StyledForm>
          </FormWrapper>
        )}
      </Formik>
      <Modal opened={modalOpened} close={() => setModalOpened(false)}>
        <Heading noMargin size="h1">
          Delete your profile?
        </Heading>
        <Heading size="h4">This action is irreversible</Heading>
        <ButtonsWrapper>
          <DeleteButton
            contain
            disabled={loadingDelete}
            loading={loadingDelete ? "Deleting..." : null}
            onClick={() => deleteUser()}
          >
            Delete
          </DeleteButton>
          <Button contain onClick={() => setModalOpened(false)}>
            Cancel
          </Button>
        </ButtonsWrapper>
        <MessageWrapper>
          <Message error show={errorDelete}>
            {errorDelete}
          </Message>
        </MessageWrapper>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ firebase, auth }) => ({
  firebase: firebase,
  loading: auth.profileEdit.loading,
  error: auth.profileEdit.error,
  loadingDelete: auth.deleteUser.loading,
  errorDelete: auth.deleteUser.error
});

const mapDispatchToProps = {
  editProfile: actions.editProfile,
  deleteUser: actions.deleteUser,
  cleanUp: actions.clean
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
