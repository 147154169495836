import React from "react";
import styled from "styled-components";

import CustomLink from "../../../components/UI/CustomLink/CustomLink";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  width: 100%;
`;

const Crumbs = ({ fullPath, room }) => {
  return (
    <div>
      <StyledBreadcrumbs>
        {fullPath &&
          fullPath
            .split("/")
            .filter(Boolean)
            .map((directory, i, arr) => (
              <CustomLink link={`/room/${arr.slice(0, i + 1).join("/")}`}>
                {room && directory === room.id ? room.name : directory}
              </CustomLink>
            ))}
      </StyledBreadcrumbs>
    </div>
  );
};

export default Crumbs;
