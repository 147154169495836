import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Backdrop from "./Backdrop/Backdrop";

const WrappedModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${({ opened }) =>
    opened ? "translate(-50%, -50%)" : "translate(-50%, -80%)"};
  transition: all 0.2s;
  width: 100%;
  max-width: 70rem;
  box-shadow: 0 0.5rem 3.5rem var(--shadow);
  border-radius: 5rem;
  background-color: var(--color-white);
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  z-index: 100;
`;

const InsideWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5rem 3rem;
`;

const Modal = ({ opened, close, children }) => {
  return ReactDOM.createPortal(
    <>
      <Backdrop opened={opened} close={close} />
      <WrappedModal opened={opened}>
        <InsideWrapper>{children}</InsideWrapper>
      </WrappedModal>
    </>,
    document.getElementById("root-modal")
  );
};

export default Modal;
