import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import Heading from "../../../components/UI/Heading/Heading";

import Modal from "../../../components/UI/Modal/Modal";
import { Container } from "../../../hoc/layout/elements";

import Loader from "../../../components/UI/Loader/Loader";

import SharingSidebar from "../SharingSidebar/SharingSidebar";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ActionButtons from "../ActionButtons/ActionButtons";
import Folders from "../Folder/Folders";
import Files from "../File/Files";
import Unpermitted from "./Unpermitted";

import UploadFiles from "../Modals/UploadFiles";
import CreateFolder from "../Modals/CreateFolder";
import FilePreview from "../Modals/FilePreview";

import * as actions from "../../../store/actions";
import { Roles } from "../../../utils/enums";

const Wrapper = styled.div`
  width: 100%;
  align-self: stretch;
  height: 100%;
  min-height: calc(100vh - 6rem);
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 4rem;
`;

const FileSystem = styled.div`
  width: 100%;
`;

const FileExplorer = styled.div`
  width: 100%;
  padding: 3rem;
`;

const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const FileModal = styled(Modal)`
  min-height: 100vh;
  border-radius: 0;
  width: 100%;
`;

const changeDirectory = (directory, currPath) => {
  let path;
  if (directory === "..") {
    path = currPath.split("/").slice(0, -1).join("/");
  } else {
    path = `${currPath}${directory}`;
  }
  return `/room/${path}/`;
};

const DataRoom = ({
  match,
  location,
  room,
  filePreviewUrl,
  filePreviewLoading,
  filePreviewType,
  getRoom,
  uploadFile,
  cleanRoom,
  createFolder,
  addShare,
  revokeShare,
}) => {
  useEffect(() => {
    let roomId = match.params.id;
    let relativePath = location.pathname
      .split("/")
      .slice(3)
      .join("/")
      .replace(/^\/+|\/+$/g, "");
    const path = relativePath ? `${roomId}/${relativePath}/` : `${roomId}/`;

    getRoom({ path: path });
    return () => {
      cleanRoom();
    };
  }, [match, location, getRoom, cleanRoom, addShare, revokeShare]);

  const [uploadModalOpened, setUploadModalOpened] = useState(false);
  const [folderModalOpened, setFolderModalOpened] = useState(false);
  const [fileModalOpened, setFileModalOpened] = useState(false);

  console.log(room);

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          {room.role ? (
            <>
              {room.role === Roles.UNPERMITTED ? (
                <Unpermitted />
              ) : (
                <>
                  <Heading noMargin size="h1">
                    {room ? room.name : ""}
                  </Heading>

                  <BodyWrapper>
                    <FileExplorer>
                      <Breadcrumbs fullPath={room.path} room={room} />

                      <FileSystem>
                        <Heading size="h2" noMargin bold textAlign="left">
                          Files
                        </Heading>

                        {room.role === "OWNER" && (
                          <ActionButtons
                            setUploadModalOpened={setUploadModalOpened}
                            setFolderModalOpened={setFolderModalOpened}
                          />
                        )}

                        <Folders
                          foldersList={room.folders}
                          fullPath={room.path}
                          changeDirectory={changeDirectory}
                          role={room.role}
                        />

                        <Files
                          filesList={room.files}
                          fullPath={room.path}
                          role={room.role}
                          setFileModalOpened={setFileModalOpened}
                        />
                      </FileSystem>
                    </FileExplorer>
                    {room.role === "OWNER" && (
                      <SharingSidebar
                        roomId={room.roomData.id}
                        sharedWith={room.roomData.sharedWith}
                        addShare={addShare}
                        revokeShare={revokeShare}
                      />
                    )}
                  </BodyWrapper>
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </InnerWrapper>
      </Container>

      <Modal
        opened={uploadModalOpened}
        close={() => setUploadModalOpened(false)}
      >
        <UploadFiles
          fullPath={room.path}
          uploadFile={uploadFile}
          setUploadModalOpened={setUploadModalOpened}
        />
      </Modal>

      <Modal
        opened={folderModalOpened}
        close={() => setFolderModalOpened(false)}
      >
        <CreateFolder
          createFolder={createFolder}
          fullPath={room.path}
          setFolderModalOpened={setFolderModalOpened}
        />
      </Modal>

      <FileModal
        opened={fileModalOpened}
        close={() => setFileModalOpened(false)}
      >
        <FilePreview
          filePreviewLoading={filePreviewLoading}
          filePreviewUrl={filePreviewUrl}
          filePreviewType={filePreviewType}
        />
      </FileModal>
    </Wrapper>
  );
};

const mapStateToProps = ({ dataRoom, firebase }) => ({
  room: dataRoom.room,
  filePreviewLoading: dataRoom.filePreview.loading,
  filePreviewUrl: dataRoom.filePreview.url,
  filePreviewType: dataRoom.filePreview.fileType,
});

const mapDispatchToProps = {
  getRoom: actions.getRoom,
  setPath: actions.setRoomPath,
  uploadFile: actions.uploadFile,
  cleanRoom: actions.cleanRoom,
  createFolder: actions.createFolder,
  addShare: actions.addShare,
  revokeShare: actions.revokeShare,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataRoom)
);
