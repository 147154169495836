import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "./hoc/layout/Layout";
import Home from "./containers/Home/Home";
import DataRoom from "./containers/DataRoom/Room/DataRoom";
import DataRooms from "./containers/DataRoom/Rooms/DataRooms";
import Login from "./containers/Auth/Login/Login";
import SignUp from "./containers/Auth/SignUp/SignUp";
import Logout from "./containers/Auth/Logout/Logout";
import VerifyEmail from "./containers/Auth/VerifyEmail/VerifyEmail";
import RecoverPassword from "./containers/Auth/RecoverPassword/RecoverPassword";
import Profile from "./containers/Auth/Profile/Profile";

function App({ loggedIn, emailVerified }) {
  let routes;

  // if (loggedIn && !emailVerified) {
  //   routes = (
  //     <Switch>
  //       <Route exact path="/verify-email" component={VerifyEmail} />
  //       <Route exact path="/signout" component={Logout} />
  //       <Redirect to="/verify-email" />
  //     </Switch>
  //   );
  // } else if (loggedIn && emailVerified)
  if (loggedIn) {
    routes = (
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/rooms" component={DataRooms} />
        <Route path="/room/:id/:path?" component={DataRoom} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/signout" component={Logout} />
        <Redirect to="/rooms" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/recover" component={RecoverPassword} />
        <Redirect to="/login" />
      </Switch>
    );
  }
  return <Layout>{routes}</Layout>;
}

const mapStateToProps = ({ firebase }) => ({
  loggedIn: firebase.auth.uid,
  emailVerified: firebase.auth.emailVerified,
});

export default connect(mapStateToProps)(App);
