import React from "react";
import styled from "styled-components";

import Heading from "../../../components/UI/Heading/Heading";
import Dropzone from "../../../components/Dropzone/Dropzone";

const DropzoneWrapper = styled.div`
  width: 100%;
`;

const UploadFiles = ({
  fullPath,
  uploadFile,
  listAll,
  setUploadModalOpened,
}) => {
  return (
    <>
      <Heading size="h1">Upload Files</Heading>
      <DropzoneWrapper>
        <Dropzone
          path={fullPath}
          uploadFile={uploadFile}
          listAll={listAll}
          setModalOpened={setUploadModalOpened}
        />
      </DropzoneWrapper>
    </>
  );
};

export default UploadFiles;
