import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik, Field } from "formik";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";

import Button from "../../../components/UI/Forms/Button/Button";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Heading from "../../../components/UI/Heading/Heading";

const ModalFormWrapper = styled(FormWrapper)`
  box-shadow: none;
  border: 0;
  padding: 0;
`;

const CreateFolderSchema = Yup.object().shape({
  folderName: Yup.string().required("Folder name is required"),
});

const CreateFolder = ({
  createFolder,
  fullPath,
  listAll,
  setFolderModalOpened,
}) => {
  return (
    <Formik
      initialValues={{
        folderName: "",
      }}
      validationSchema={CreateFolderSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await createFolder({
          path: fullPath,
          folder: values.folderName,
        });
        resetForm({});
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <ModalFormWrapper>
          <StyledForm>
            <Heading noMargin size="h1">
              New Folder
            </Heading>

            <Label>Folder Name</Label>
            <Field type="text" name="folderName" component={Input} />

            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              onClick={() => {
                setFolderModalOpened(false);
              }}
            >
              Create New Folder
            </Button>
          </StyledForm>
        </ModalFormWrapper>
      )}
    </Formik>
  );
};

export default CreateFolder;
