import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "data-room-167af.firebaseapp.com",
  databaseURL: "https://data-room-167af.firebaseio.com",
  projectId: "data-room-167af",
  storageBucket: "data-room-167af.appspot.com",
  messagingSenderId: "253431336272",
  appId: "1:253431336272:web:e3d1e0s78b91c0bdacfb055",
  measurementId: "G-0DYZ9BLW6Z",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();

export default firebase;
