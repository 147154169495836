import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading/Heading";
import Message from "../../../components/UI/Forms/Message/Message";

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const RecoverySchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required.")
});

const RecoverPassword = ({ loading, error, recoverPassword, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={RecoverySchema}
      onSubmit={async (values, { setSubmitting }) => {
        await recoverPassword(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <StyledForm>
            <Heading size="h1" noMargin>
              Recover your password
            </Heading>
            <Heading size="h4" bold>
              Type in your email to recover your password
            </Heading>
            <Label>Email</Label>
            <Field type="email" name="email" component={Input} />
            <Button
              disabled={!isValid || isSubmitting}
              loading={loading ? "Sending Recovery Email" : null}
              type="submit"
            >
              Recover Email
            </Button>
            <MessageWrapper>
              <Message error show={error}>
                {error}
              </Message>
            </MessageWrapper>
            <MessageWrapper>
              <Message success show={error === false}>
                Message sent successfully
              </Message>
            </MessageWrapper>
          </StyledForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.recoverPassword.loading,
  error: auth.recoverPassword.error
});

const mapDispatchToProps = {
  recoverPassword: actions.recoverPassword,
  cleanUp: actions.clean
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
