import React from "react";
import styled, { css } from "styled-components";

const baseStyle = css`
  color: ${props =>
    props.color === "white" ? "var(--color-white)" : "var(--color-text)"};
  font-weight: ${props => (props.bold ? "700" : "300")};
  margin-top: 0;
  margin-bottom: ${props => (props.noMargin ? "0rem" : "2rem")};
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
`;

const Heading1 = styled.h1`
  font-size: 2rem;
  text-transform: uppercase;
  ${baseStyle}
`;

const Heading2 = styled.h2`
  font-size: 1.8rem;
  ${baseStyle}
`;

const Heading3 = styled.h3`
  font-size: 1.5rem;
  ${baseStyle}
`;

const Heading4 = styled.h4`
  font-size: 1.3rem;
  ${baseStyle}
`;

const Heading = ({ children, color, noMargin, size, bold, textAlign }) => {
  if (size === "h1")
    return (
      <Heading1
        noMargin={noMargin}
        color={color}
        bold={bold}
        textAlign={textAlign}
      >
        {children}
      </Heading1>
    );
  else if (size === "h2")
    return (
      <Heading2
        noMargin={noMargin}
        color={color}
        bold={bold}
        textAlign={textAlign}
      >
        {children}
      </Heading2>
    );
  else if (size === "h3")
    return (
      <Heading3
        noMargin={noMargin}
        color={color}
        bold={bold}
        textAlign={textAlign}
      >
        {children}
      </Heading3>
    );
  else
    return (
      <Heading4
        noMargin={noMargin}
        color={color}
        bold={bold}
        textAlign={textAlign}
      >
        {children}
      </Heading4>
    );
};

export default Heading;
