import * as actions from "../actions/actionTypes";

// sign up action creators
export const signUp = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  dispatch({ type: actions.AUTH_START });
  try {
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password);

    await firebase.auth().currentUser.sendEmailVerification();

    await firestore.collection("users").doc(res.user.uid).set({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    });
    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};

// sign out action creators
export const signOut = () => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  try {
    await firebase.auth().signOut();
  } catch (err) {
    console.log(err.message);
  }
};

// log in action creators
export const signIn = (data) => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  dispatch({ type: actions.AUTH_START });
  try {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }

  dispatch({ type: actions.AUTH_END });
};

// clean up messages
export const clean = () => ({
  type: actions.CLEAN_UP,
});

// verify email action
export const verifyEmail = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.VERIFY_START });
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    dispatch({ type: actions.VERIFY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.VERIFY_FAIL, payload: err.message });
  }
};

// recover email action
export const recoverPassword = (data) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: actions.RESET_PASSWORD_START });
  try {
    await firebase.auth().sendPasswordResetEmail(data.email);
    dispatch({ type: actions.RESET_PASSWORD_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.RESET_PASSWORD_FAIL, payload: err.message });
  }
};

// edit profile
export const editProfile = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const { uid: userId, email: userEmail } = getState().firebase.auth;
  dispatch({ type: actions.PROFILE_EDIT_START });

  try {
    if (data.email !== userEmail) {
      await user.updateEmail(data.email);
    }

    if (data.password.length > 0) {
      await user.updatePassword(data.password);
    }

    await firestore.collection("users").doc(userId).update({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    });

    dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

// delete user
export const deleteUser = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const userID = getState().firebase.auth.uid;

  dispatch({ type: actions.DELETE_USER_START });
  try {
    await firestore.collection("users").doc(userID).delete();

    await user.delete();
    dispatch({ type: actions.DELETE_USER_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_USER_FAIL, payload: err.message });
  }
};
