import React from "react";
import styled from "styled-components";

import Button from "../../../components/UI/Forms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faFolder } from "@fortawesome/free-solid-svg-icons";

const ButtonsWrapper = styled.div``;

const ActionButton = styled(Button)`
  border-radius: 0.5rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ActionIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

const ActionButtons = ({ setUploadModalOpened, setFolderModalOpened }) => {
  return (
    <div>
      <ButtonsWrapper>
        <ActionButton contain onClick={() => setUploadModalOpened(true)}>
          <ActionIcon icon={faUpload} />
          Upload
        </ActionButton>
        <ActionButton contain onClick={() => setFolderModalOpened(true)}>
          <ActionIcon icon={faFolder} />
          New Folder
        </ActionButton>
      </ButtonsWrapper>
    </div>
  );
};

export default ActionButtons;
