import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import CloseIcon from "@material-ui/icons/Close";

import * as actions from "../../../store/actions";

import CustomLink from "../../../components/UI/CustomLink/CustomLink";

const StyledFolder = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
`;

const FolderIcon = styled(FolderOpenOutlinedIcon)`
  font-size: 1.5rem;
  margin: 0.5rem;
  color: rgba(0, 0, 0, 0.7);
`;

const FileStart = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActionIcon = styled(CloseIcon)`
  margin: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

const FileDetails = styled.p`
  font-weight: 400;
`;

const Folder = ({ role, folder, path, link, deleteFolder }) => {
  return (
    <StyledFolder key={folder}>
      <CustomLink link={link} noMargin noPadding>
        <FileStart>
          <FolderIcon fontSize="large" />
          <FileDetails>{folder}</FileDetails>
        </FileStart>
      </CustomLink>
      <div>
        {role === "OWNER" && (
          <ActionIcon
            onClick={async () => {
              await deleteFolder({ path: path, folder: folder });
            }}
          />
        )}
      </div>
    </StyledFolder>
  );
};

const mapDispatchToProps = {
  deleteFolder: actions.deleteFolder,
};

export default connect(null, mapDispatchToProps)(Folder);
