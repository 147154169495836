import React from "react";
import styled from "styled-components";

const StyledLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => (props.size ? `${props.size}` : "64px")};
    height: ${(props) => (props.size ? `${props.size}` : "64px")};
    margin: 8px;
    border: ${(props) =>
      props.color ? `8px solid ${props.color}` : "8px solid #3b5998"};

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) =>
      props.color
        ? `${props.color} transparent transparent transparent`
        : `#3b5998 transparent transparent transparent`};
    border-width: ${(props) =>
      props.borderWidth ? `${props.borderWidth}` : `8px`};

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ color, size, borderWidth }) => {
  return (
    <StyledLoader color={color} size={size} borderWidth={borderWidth}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  );
};

export default Loader;
