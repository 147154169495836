export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const CLEAN_UP = "CLEAN_UP";
export const CLEAN_ROOM = "CLEAN_ROOM";

export const VERIFY_START = "VERIFY_START";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const PROFILE_EDIT_START = "PROFILE_EDIT_START";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAIL = "PROFILE_EDIT_FAIL";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPLOAD_FILE_START = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

export const DELETE_FILE_START = "DELETE_FILE_START";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

export const DOWNLOAD_FILE_START = "DOWNLOAD_FILE_START";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL";

export const LIST_FILES = "LIST_FILES";
export const LIST_FOLDERS = "LIST_FOLDERS";

export const SET_ROOM = "SET_ROOM";
export const SET_ROLE = "SET_ROLE";
export const SET_PATH = "SET_PATH";
export const SET_ROOMS = "SET_ROOMS";
export const SET_SHARE = "SET_SHARE";

export const CREATE_ROOM_START = "CREATE_ROOM_START";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_FAIL = "CREATE_ROOM_FAIL";

export const DELETE_ROOM_START = "DELETE_ROOM_START";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_FAIL = "DELETE_ROOM_FAIL";

export const CREATE_FOLDER_START = "CREATE_FOLDER_START";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAIL = "CREATE_FOLDER_FAIL";

export const DELETE_FOLDER_START = "CREATE_FOLDER_START";
export const DELETE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAIL = "CREATE_FOLDER_FAIL";

export const FILE_PREVIEW_START = "FILE_PREVIEW_START";
export const FILE_PREVIEW_SUCCESS = "FILE_PREVIEW_SUCCESS";
export const FILE_PREVIEW_FAIL = "FILE_PREVIEW_FAIL";
