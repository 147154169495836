import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloseIcon from "@material-ui/icons/Close";

import * as actions from "../../../store/actions";

const StyledFile = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
`;

const FileStart = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
`;

const FileIcon = styled(DescriptionOutlinedIcon)`
  font-size: 1.5rem;
  margin: 0.5rem;
  color: rgba(0, 0, 0, 0.7);
`;

const ActionIcon = styled(CloseIcon)`
  margin: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

const FileDetails = styled.div`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
`;

const File = ({
  role,
  fileName,
  path,
  setFileModalOpened,
  deleteFile,
  downloadFile,
  previewFile,
}) => {
  return (
    <StyledFile>
      <div
        href="/"
        onClick={async () => {
          setFileModalOpened(true);
          await previewFile({ path: path, fileName: fileName });
          return false;
        }}
      >
        <FileStart>
          <FileIcon fontSize="large" />
          <FileDetails>{fileName}</FileDetails>
        </FileStart>
      </div>
      <div>
        {role === "OWNER" && (
          <ActionIcon
            onClick={async () => {
              await deleteFile({ path: path, fileName: fileName });
            }}
          />
        )}
      </div>
    </StyledFile>
  );
};

const mapDispatchToProps = {
  deleteFile: actions.deleteFile,
  downloadFile: actions.downloadFile,
  previewFile: actions.previewFile,
};

export default connect(null, mapDispatchToProps)(File);
