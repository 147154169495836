import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading/Heading";
import Message from "../../../components/UI/Forms/Message/Message";
import CustomLink from "../../../components/UI/CustomLink/CustomLink";

import * as actions from "../../../store/actions";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string().required("The password is required."),
});

const Login = ({ login, loading, error, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await login(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <StyledForm>
            <Heading noMargin size="h1">
              Welcome Back!
            </Heading>
            <Heading size="h4">Login to your account</Heading>

            <Label>Email</Label>
            <Field type="email" name="email" component={Input} />

            <Label>Password</Label>
            <Field type="password" name="password" component={Input} />

            <Button
              disabled={!isValid || isSubmitting}
              loading={loading ? "Logging In..." : null}
              type="submit"
            >
              Log In
            </Button>
            <CustomLink link="/recover">Forgot your password?</CustomLink>
            <Message error show={error}>
              {error}
            </Message>
          </StyledForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});

const mapDispatchToProps = {
  login: actions.signIn,
  cleanUp: actions.clean,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
