import React, { useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const StyledDropzone = styled.div`
  border: 2px dashed black;
  padding: 3rem 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Dropzone = ({ path, uploadFile, listAll, setModalOpened }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        console.log(file);
        await uploadFile({
          path: path,
          file: file,
        });
      });
      setModalOpened(false);
    },
    [path]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <StyledDropzone {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>
    </StyledDropzone>
  );
};

export default Dropzone;
