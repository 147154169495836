import React from "react";
import styled from "styled-components";
import Folder from "./Folder";

const StyledFolders = styled.ul`
  list-style-type: none;
  width: 100%;
`;

const Folders = ({ foldersList, fullPath, role, changeDirectory }) => {
  return (
    <div>
      <StyledFolders>
        {foldersList &&
          foldersList.map((folder) => (
            <Folder
              key={folder}
              folder={folder}
              path={fullPath}
              link={changeDirectory(folder, fullPath)}
              role={role}
            />
          ))}
      </StyledFolders>
    </div>
  );
};

export default Folders;
