import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  Container,
  FormWrapper,
  StyledForm,
} from "../../../hoc/layout/elements";
import Heading from "../../../components/UI/Heading/Heading";
import Button from "../../../components/UI/Forms/Button/Button";
import Label from "../../../components/UI/Forms/Label/Label";
import Input from "../../../components/UI/Forms/Input/Input";
import CustomLink from "../../../components/UI/CustomLink/CustomLink";
import Modal from "../../../components/UI/Modal/Modal";
import Loader from "../../../components/UI/Loader/Loader";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import CloseIcon from "@material-ui/icons/Close";

import * as actions from "../../../store/actions";

const Wrapper = styled.div`
  width: 100%;
  align-self: stretch;
  height: 100%;
  min-height: calc(100vh - 6rem);
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5rem 4rem;
`;

const Rooms = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RoomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  max-width: 60rem;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
`;

const Room = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: 35rem; */
`;

const ModalFormWrapper = styled(FormWrapper)`
  box-shadow: none;
  border: 0;
`;

const HalfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 75vh;

  &:not(:last-child) {
    border-right: 1px solid grey;
  }
`;

const CreateRoomSchema = Yup.object().shape({
  roomName: Yup.string().required("Name is required."),
});

const DataRooms = ({
  owned,
  shared,
  rooms,
  getRooms,
  cleanUp,
  createRoom,
  deleteRoom,
}) => {
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    getRooms();

    return () => {
      cleanUp();
    };
  }, [getRooms, cleanUp]);

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <HalfWrapper>
            <Heading size="h1">My Rooms</Heading>
            <Rooms>
              {owned ? (
                owned.map((room) => (
                  <RoomWrapper>
                    <Room>
                      <HomeOutlinedIcon fontSize="large" />
                      <CustomLink noMargin link={`/room/${room.id}/`}>
                        {room.name}
                      </CustomLink>
                      <div>
                        <CloseIcon
                          fontSize="large"
                          onClick={async () => {
                            await deleteRoom({ roomId: room.id });
                          }}
                        />
                      </div>
                    </Room>
                  </RoomWrapper>
                ))
              ) : (
                <Loader size={"48px"} borderWidth={"6px"} />
              )}
            </Rooms>
            <Button contain onClick={() => setModalOpened(true)}>
              Add Room
            </Button>
          </HalfWrapper>

          <HalfWrapper>
            <Heading size="h1">Shared With Me</Heading>
            <Rooms>
              {shared ? (
                shared.map((room) => (
                  <RoomWrapper>
                    <Room>
                      <HomeOutlinedIcon fontSize="large" />
                      <CustomLink noMargin link={`/room/${room.id}/`}>
                        {room.name}
                      </CustomLink>
                      <div></div>
                    </Room>
                  </RoomWrapper>
                ))
              ) : (
                <Loader size={"48px"} borderWidth={"6px"} />
              )}
            </Rooms>
          </HalfWrapper>

          <Modal opened={modalOpened} close={() => setModalOpened(false)}>
            <Formik
              initialValues={{
                roomName: "",
              }}
              validationSchema={CreateRoomSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await createRoom(values);
                resetForm({});
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, isValid }) => (
                <ModalFormWrapper>
                  <StyledForm>
                    <Heading noMargin size="h1">
                      Add a Room
                    </Heading>

                    <Label>Room Name</Label>
                    <Field type="text" name="roomName" component={Input} />

                    <Button
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      onClick={() => {
                        setModalOpened(false);
                      }}
                    >
                      Create New Room
                    </Button>
                  </StyledForm>
                </ModalFormWrapper>
              )}
            </Formik>
          </Modal>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = ({ dataRoom }) => ({
  owned: dataRoom.rooms.owned,
  shared: dataRoom.rooms.shared,
});

const mapDispatchToProps = {
  getRooms: actions.getRooms,
  createRoom: actions.createRoom,
  deleteRoom: actions.deleteRoom,
  cleanUp: actions.cleanRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRooms);
