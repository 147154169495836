import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Navbar from "../../components/Navigation/Navbar/Navbar";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";

const MainWrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  min-height: calc(100vh - 6rem);
  margin: 0 auto;
  margin-top: 6rem;
  padding: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Layout = ({ children, loggedIn }) => (
  <>
    <Navbar loggedIn={loggedIn} />
    <SideDrawer loggedIn={loggedIn} />
    <MainWrapper>{children}</MainWrapper>
  </>
);

const mapStateToProps = ({ firebase }) => ({
  loggedIn: firebase.auth
});

export default connect(mapStateToProps)(Layout);
