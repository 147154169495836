import * as actions from "../actions/actionTypes";

const initialState = {
  room: {
    role: null,
    path: null,
    folders: null,
    files: null,
    roomData: {
      id: null,
      name: null,
      ownedBy: null,
      sharedWith: null,
    },
  },
  rooms: {
    owned: null,
    shared: null,
  },
  uploadFile: {
    loading: false,
    error: null,
  },
  deleteFile: {
    loading: false,
    error: null,
  },
  downloadFile: {
    loading: false,
    error: null,
  },
  createFolder: {
    loading: false,
    error: null,
  },
  deleteFolder: {
    loading: false,
    error: null,
  },
  createRoom: {
    loading: false,
    error: null,
  },
  deleteRoom: {
    loading: false,
    error: null,
  },
  filePreview: {
    loading: false,
    error: null,
    url: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.UPLOAD_FILE_START:
      return { ...state, uploadFile: { ...state.uploadFile, loading: true } };

    case actions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        room: {
          ...state.room,
          files: [...state.room.files, payload.fileName],
        },
        addFile: { ...state.uploadFile, loading: false, error: null },
      };

    case actions.UPLOAD_FILE_FAIL:
      return {
        ...state,
        addFile: { ...state.uploadFile, loading: false, error: payload },
      };

    case actions.DELETE_FILE_START:
      return { ...state, deleteFile: { ...state.deleteFile, loading: true } };

    case actions.DELETE_FILE_SUCCESS:
      return {
        ...state,
        room: {
          ...state.room,
          files: state.room.files.filter((file) => file !== payload),
        },
        deleteFile: { ...state.deleteFile, loading: false, error: null },
      };

    case actions.DELETE_FILE_FAIL:
      return {
        ...state,
        deleteFile: { ...state.deleteFile, loading: false, error: payload },
      };

    case actions.DOWNLOAD_FILE_START:
      return {
        ...state,
        downloadFile: { ...state.downloadFile, loading: true },
      };

    case actions.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        deleteFile: { ...state.downloadFile, loading: false, error: null },
      };

    case actions.DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        deleteFile: { ...state.downloadFile, loading: false, error: payload },
      };

    case actions.SET_ROOM:
      return {
        ...state,
        room: {
          ...state.room,
          role: payload.role,
          path: payload.path,
          folders: payload.folders,
          files: payload.files,
          roomData: {
            ...state.room.roomData,
            id: payload.roomData.id,
            name: payload.roomData.name,
            ownedBy: payload.roomData.owned_by,
            sharedWith: payload.roomData.shared_with,
          },
        },
      };

    case actions.SET_ROOMS:
      return {
        ...state,
        rooms: {
          owned: payload.owned,
          shared: payload.shared,
        },
      };

    case actions.CREATE_ROOM_START:
      return {
        ...state,
        createRoom: { ...state.createRoom, loading: true },
      };

    case actions.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          owned: [...state.rooms.owned, payload],
        },
        createRoom: { ...state.createRoom, loading: false },
      };

    case actions.CREATE_ROOM_FAIL:
      return {
        ...state,
        createRoom: { ...state.createRoom, loading: false, error: payload },
      };

    case actions.DELETE_ROOM_START:
      return { ...state, deleteRoom: { ...state.deleteRoom, loading: true } };

    case actions.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          owned: state.rooms.owned.filter((room) => room.id !== payload),
        },
        deleteRoom: { ...state.deleteRoom, loading: false },
      };

    case actions.DELETE_ROOM_FAIL:
      return {
        ...state,
        deleteRoom: { ...state.deleteRoom, loading: false, error: payload },
      };

    case actions.FILE_PREVIEW_START:
      return { ...state, filePreview: { ...state.filePreview, loading: true } };

    case actions.FILE_PREVIEW_SUCCESS:
      return {
        ...state,
        filePreview: {
          ...state.filePreview,
          loading: false,
          url: payload.url,
          fileType: payload.fileType,
        },
      };

    case actions.FILE_PREVIEW_FAIL:
      return {
        ...state,
        deleteRoom: { ...state.filePreview, loading: false, error: payload },
      };

    case actions.CREATE_FOLDER_START:
      return {
        ...state,
        createFolder: { ...state.createFolder, loading: true },
      };

    case actions.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        room: {
          ...state.room,
          folders: [...state.room.folders, payload],
        },
        createFolder: { ...state.createFolder, loading: false },
      };

    case actions.CREATE_FOLDER_FAIL:
      return {
        ...state,
        createFolder: { ...state.createFolder, loading: false, error: payload },
      };

    case actions.DELETE_FOLDER_START:
      return {
        ...state,
        deleteFolder: { ...state.createFolder, loading: true },
      };

    case actions.DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        room: {
          ...state.room,
          folders: state.room.folders.filter((folder) => folder !== payload),
        },
        deleteFolder: { ...state.createFolder, loading: false },
      };

    case actions.DELETE_FOLDER_FAIL:
      return {
        ...state,
        deleteFolder: { ...state.createFolder, loading: false, error: payload },
      };

    case actions.CLEAN_ROOM:
      return {
        room: {
          id: null,
          name: null,
          ownedBy: null,
          sharedWith: null,
        },
        role: null,
        path: null,
        rooms: {
          owned: [],
          shared: [],
        },
        folders: [],
        files: [],
        uploadFile: {
          loading: false,
          error: null,
        },
        deleteFile: {
          loading: false,
          error: null,
        },
        downloadFile: {
          loading: false,
          error: null,
        },
        createRoom: {
          loading: false,
          error: null,
        },
        createFolder: {
          loading: false,
          error: null,
        },
        deleteRoom: {
          loading: false,
          error: null,
        },
        filePreview: {
          loading: false,
          error: null,
          url: null,
        },
      };

    default:
      return state;
  }
};
