import React from "react";
import styled from "styled-components";
import { Player } from "video-react";

import Loader from "../../../components/UI/Loader/Loader";

const IFrame = styled.iframe`
  display: flex;
  height: calc(100vh + 50px);
`;

const StyledFilePreview = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FilePreview = ({
  filePreviewLoading,
  filePreviewUrl,
  filePreviewType,
}) => {
  if (filePreviewUrl === null) {
    return null;
  }
  if (filePreviewLoading) {
    return <Loader />;
  }
  console.log(filePreviewType);

  const microsoft = "https://view.officeapps.live.com/op/embed.aspx?src=";
  // const google = "https://docs.google.com/gview?url=";

  let preview;
  if (
    filePreviewType === "pdf" ||
    filePreviewType === "jpg" ||
    filePreviewType === "jpeg" ||
    filePreviewType === "png"
  ) {
    const fullUrl = `${filePreviewUrl}#toolbar=0`;
    preview = (
      <IFrame
        src={fullUrl}
        width="1000px"
        height="700px"
        frameborder="0"
        title="filePreview"
      />
    );
  } else if (filePreviewType === "mp4") {
    preview = <Player src={filePreviewUrl} />;
  } else {
    const fullUrl = `${microsoft}${encodeURIComponent(
      filePreviewUrl
    )}&embedded=true`;
    // fullUrl = `${google}${url}&embedded=true`;
    preview = (
      <IFrame
        src={fullUrl}
        width="1000px"
        height="700px"
        frameborder="0"
        title="filePreview"
      />
    );
  }

  return <StyledFilePreview>{preview}</StyledFilePreview>;
};

export default FilePreview;
