import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  margin: 0 auto;
  width: ${({ contain }) => (contain ? "auto" : "100%")};
  padding: 0.8rem 3rem;
  margin: 1rem 0;
  line-height: 2rem;
  border-radius: 2rem;
  color: var(--color-text);
  font-size: 1.5rem;
  background-color: ${props => props.color};
  transition: all 0.2s;

  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(2px);
  }
`;

const Button = ({ children, disabled, loading, contain, color, ...rest }) => {
  return (
    <StyledButton color={color} disabled={disabled} contain={contain} {...rest}>
      {loading ? loading : children}
    </StyledButton>
  );
};

export default Button;
