import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  display: block;
  margin: auto 0;

  font-size: 12px;
  color: var(--color-text);
  text-transform: uppercase;
  align-self: ${({ align }) => {
    if (align === "right") {
      return "flex-end";
    } else if (align === "center") {
      return "center";
    } else {
      return "flex-start";
    }
  }};
`;

const Label = ({ children, align }) => {
  return <StyledLabel align={align}>{children}</StyledLabel>;
};

export default Label;
